import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Footer from "./components/footer/Footer";
import Headerf from "./components/header/Headerf";
import Headers from "./components/header/Headers";
import Login from "./components/user/Login";
import About from "./page/About";
import Contact from "./page/Contact";
import Home from "./page/Home";
import Career from "./page/innerPage/Career";
import Faq from "./page/innerPage/Faq";
import Gallery from "./page/innerPage/Gallery";
import Internship from "./page/innerPage/Internship";
import Leader from "./page/innerPage/Leader";
import News from "./page/innerPage/News";
import Partners from "./page/innerPage/Partners";
import Programs from "./page/innerPage/Programs";
import Publication from "./page/innerPage/Publication";
import SpeechVideos from "./page/innerPage/SpeechVideos";
import Volunteer from "./page/innerPage/Volunteer";
import Welcome from "./page/Welcome";

const App = () => {
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    setTimeout(() => setLoading(false), 2000);
  }, []);

  return (
    <>
      {loading === false ? (
        <>
          <Router>
            <Headerf />
            <Headers />
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/contact-us" element={<Contact />} />
              <Route path="/login" element={<Login />} />
              <Route path="/faq" element={<Faq />} />
              <Route path="/contact-us" element={<Contact />} />
              <Route path="/leadership" element={<Leader />} />
              <Route path="/about-us" element={<About />} />
              <Route path="/programs" element={<Programs />} />
              <Route path="/news-activities" element={<News />} />
              <Route path="/publication" element={<Publication />} />
              <Route path="/speech-videos" element={<SpeechVideos />} />
              <Route path="/gallery" element={<Gallery />} />
              <Route path="/internship" element={<Internship />} />
              <Route path="/volunteer" element={<Volunteer />} />
              <Route path="/partnership" element={<Partners />} />
              <Route path="/career" element={<Career />} />
            </Routes>
            <Footer />
          </Router>
        </>
      ) : (
        <Welcome />
      )}
    </>
  );
};

export default App;
