import React from "react";
import "./style.css";
import { BiBuildings, BiEnvelope } from "react-icons/bi";

const BusinessThinktank = () => {
  return (
    <div>
      <div className="p-md-3 thinktank">
        <div className="think p-3 p-md-5">
          <h2>Leadership</h2>
        </div>
      </div>
      <div className="leader-title pt-5">
        <h3>Advisory Team </h3>
      </div>
      <div className="pb-5 pt-4 cards">
        <div className="col-lg-6 p-4 mb-3 mb-md-0">
          <div className="business-card p-4">
            <div className="col-md-4">
              <img
                src="./images/advisory/surendrapandey.jpg"
                className="w-100"
                alt=""
              />
            </div>
            <div className="col-md-8 ps-4">
              <div className="business-card-content pt-2">
                <h3>Mr. Surendra Pandey</h3>
                <p>Former Finance Minister</p>
                <li>
                  <BiBuildings />
                  Government of Nepal
                </li>
                {/* <li>
                  <BiEnvelope /> president@chaudharygroup.com
                </li> */}
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-6 p-4 mb-3 mb-md-0">
          <div className="business-card p-4">
            <div className="col-md-4">
              <img
                src="./images/advisory/shankhar.jfif"
                className="w-100"
                alt=""
              />
            </div>
            <div className="col-md-8 ps-4">
              <div className="business-card-content pt-2">
                <h3>Mr. Shankar Prasad Koirala</h3>
                <p>Former Finance Minister / Secretary</p>
                <li>
                  <BiBuildings /> Government of Nepal
                </li>
                {/* <li>
                  <BiEnvelope /> president@chaudharygroup.com
                </li> */}
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-6 p-4 mb-3 mb-md-0">
          <div className="business-card p-4">
            <div className="col-md-4">
              <img src="./images/advisory/pawan.jpg" className="w-100" alt="" />
            </div>
            <div className="col-md-8 ps-4">
              <div className="business-card-content pt-2">
                <h3>Mr. Pawan Golyan </h3>
                <p>Senior Business Leader / Chairman</p>
                <li>
                  <BiBuildings /> CBFIN
                </li>
                {/* <li>
                  <BiEnvelope /> president@chaudharygroup.com
                </li> */}
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-6 p-4 mb-3 mb-md-0">
          <div className="business-card p-4">
            <div className="col-md-4">
              <img
                src="./images/advisory/bhawani.jpg"
                className="w-100"
                alt=""
              />
            </div>
            <div className="col-md-8 ps-4">
              <div className="business-card-content pt-2">
                <h3>Ms. Bhawani Rana</h3>
                <p>Senior Business Leader / Former Chairman</p>
                <li>
                  <BiBuildings />
                  FNCCI
                </li>
                {/* <li>
                  <BiEnvelope /> president@chaudharygroup.com
                </li> */}
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-6 p-4 mb-3 mb-md-0">
          <div className="business-card p-4">
            <div className="col-md-4">
              <img
                src="./images/advisory/sidhant.jpg"
                className="w-100"
                alt=""
              />
            </div>
            <div className="col-md-8 ps-4">
              <div className="business-card-content pt-2">
                <h3>Mr. Siddhantraj Pandey</h3>
                <p>Chairman</p>
                <li>
                  <BiBuildings />
                  Business Oxygen
                </li>
                {/* <li>
                  <BiEnvelope /> president@chaudharygroup.com
                </li> */}
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-6 p-4 mb-3 mb-md-0">
          <div className="business-card p-4">
            <div className="col-md-4">
              <img
                src="./images/advisory/bishal.jpg"
                className="w-100"
                alt=""
              />
            </div>
            <div className="col-md-8 ps-4">
              <div className="business-card-content pt-2">
                <h3>Mr. Bishal Gaire </h3>
                <p>Member Secretary </p>
                <li>
                  <BiBuildings /> Chairman of NBI
                </li>
                {/* <li>
                  <BiEnvelope /> president@chaudharygroup.com
                </li> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BusinessThinktank;
