import React from "react";
import "./style.css";

const Programs = () => {
  return (
    <div>
      <div className="about-head p-3 p-md-5">
        <div className="container ">
          <h2 className="text-center">Programs</h2>
        </div>
      </div>
      <div className="container about pt-2 pt-md-5 pb-2 pb-md-5">
        <div className="row">
          <div className="col-lg-4 col-md-6 mb-4">
            <div className="program-card">
              <h5>Skill Lab Program</h5>
              <p>
                The skill lab program includes trainings in entrepreneurship,
                leadership development, corporate HR, corporate business
                seminars, youth entrepreneurship and mentorship,
                self-employment, and other trainings.{" "}
              </p>
            </div>
          </div>
          <div className="col-lg-4 col-md-6  mb-4">
            <div className="program-card">
              <h5>Summit & Awards Programs </h5>
              <p>
                Numerous high-level summits, conferences, awards and seminars
                have been held, including Nepal Business Summit/Award Program,
                Economic Summit, Provincial Entrepreneurship Seminar.
              </p>
            </div>
          </div>
          <div className="col-lg-4 col-md-6  mb-4">
            <div className="program-card">
              <h5>Start-up Fund Program </h5>
              <p>
                A startup mentoring program is in operation to encourage youth
                towards entrepreneurship. The startup fund program also includes
                counseling and seed capital opportunities, counseling, and
                training in innovation and entrepreneurship.
              </p>
            </div>
          </div>
          <div className="col-lg-4 col-md-6  mb-4">
            <div className="program-card">
              <h5>Business Incubation Program. </h5>
              <p>
                Business incubators are specially designed programs to support
                the innovation and expansion of emerging enterprises. They
                usually provide workspaces, mentorship, education and access to
                investors for startups or solo entrepreneurs.
              </p>
            </div>
          </div>
          <div className="col-lg-4 col-md-6  mb-4">
            <div className="program-card">
              <h5>Industrial visits Program </h5>
              <p>
                Interprovincial and international industrial tour programs have
                been designed to help students strengthen their business
                abilities and learn firsthand about the successes and failures
                of various businesses. A 5 to 7 day tour package is available
                for interprovincial travel to Biratnagar, Birganj, Butwal, and
                Pokhara; for international travel, this package is available for
                Thailand, Malaysia, Singapore, Dubai, and other nations. Certain
                requirements must be met in order to participate, and a package
                program during the visit that includes seminars, conferences,
                entertainment, etc. has also been designed.
              </p>
            </div>
          </div>
          <div className="col-lg-4 col-md-6  mb-4">
            <div className="program-card">
              <h5>Business bootcamp programs </h5>
              <p>
                Bootcamp is a global network of business-specific startup
                accelerators that provides investment and mentorship. This
                mentorship-driven strategy is made possible by startup bootcamp,
                which equips entrepreneurs with crucial resources during a
                three-month accelerator program.
              </p>
            </div>
          </div>
          <div className="col-lg-4 col-md-6  mb-4">
            <div className="program-card">
              <h5>Fintech Program </h5>
              <p>
                This program has been conducted to increase financial literacy
                and financial access. It includes programs about the fintech
                industry as a whole, the digital economy, digital platforms and
                banks, financial sector information, artificial intelligence,
                and blockchain and cryptocurrency awareness.
              </p>
            </div>
          </div>
          <div className="col-lg-4 col-md-6  mb-4">
            <div className="program-card">
              <h5>Business consultant </h5>
              <p>
                NBI serves as a consultant on issues pertaining to portfolio
                management, industry analysis, investment, employee quality and
                business operations
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Programs;
