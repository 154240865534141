import React from "react";
import "./style.css";

const Partners = () => {
  return (
    <div>
      <div className="about-head p-3 p-md-5">
        <div className="container ">
          <h2 className="text-center">Partnership</h2>
        </div>
      </div>
      <div className="container about  pb-2 pb-md-5 pb-5 pt-5">
        <div className="chronicles">
          <div className="chronicle-card mb-5">
            <div className="card-inner">
              <div className="first-front">
                <div className="imgs">
                  <img src="./images/summitaward.jpg" alt="" />
                </div>

                <div className="conss">
                  <p>
                    <h5>नेपाल सरकार अर्थ मन्त्रालय</h5>

                    <button>
                      <img
                        src="./images/go.jpg"
                        height={40}
                        width={40}
                        alt=""
                      />
                    </button>
                  </p>
                </div>
              </div>
              <div className="chro-btn">
                <img src="./images/logo.png" alt="" />

                <button>Read More </button>
                <p className="ps-5">नेपाल सरकार अर्थ मन्त्रालय</p>
              </div>
            </div>
          </div>
          <div className="chronicle-card  mb-5">
            <div className="card-inner">
              <div className="first-front">
                <div className="imgs">
                  <img src="./images/summitaward.jpg" alt="" />
                </div>

                <div className="conss">
                  <p>
                    <h5> नेपाल सरकार उद्योग, वाणिज्य तथा आपूर्ति मन्त्रालय</h5>

                    <button>
                      <img
                        src="./images/go.jpg"
                        height={40}
                        width={40}
                        alt=""
                      />
                    </button>
                  </p>
                </div>
              </div>
              <div className="chro-btn">
                <img src="./images/logo.png" alt="" />

                <button>Read More </button>
                <p>नेपाल सरकार उद्योग, वाणिज्य तथा आपूर्ति मन्त्रालय</p>
              </div>
            </div>
          </div>
          <div className="chronicle-card mb-5">
            <div className="card-inner">
              <div className="first-front">
                <div className="imgs">
                  <img src="./images/summitaward.jpg" alt="" />
                </div>

                <div className="conss">
                  <p>
                    <h5>राष्ट्रिय वाणिज्य बैंक</h5>

                    <button>
                      <img
                        src="./images/rbb.jpg"
                        height={20}
                        width={40}
                        alt=""
                      />
                    </button>
                  </p>
                </div>
              </div>
              <div className="chro-btn">
                <img src="./images/logo.png" alt="" />

                <button>Read More </button>
                <p className="ps-5 ms-4">राष्ट्रिय वाणिज्य बैंक</p>
              </div>
            </div>
          </div>
          <div className="chronicle-card  mb-5">
            <div className="card-inner">
              <div className="first-front">
                <div className="imgs">
                  <img src="./images/summitaward.jpg" alt="" />
                </div>

                <div className="conss">
                  <p>
                    <h5>नेपाल उधोग परिसंघ – (सिएनआई )</h5>

                    <button>
                      <img
                        src="./images/cin.jpg"
                        height={40}
                        width={40}
                        alt=""
                      />
                    </button>
                  </p>
                </div>
              </div>
              <div className="chro-btn">
                <img src="./images/logo.png" alt="" />

                <button>Read More </button>
                <p className="ps-4 ms-2">नेपाल उधोग परिसंघ – (सिएनआई )</p>
              </div>
            </div>
          </div>
          <div className="chronicle-card  mb-5">
            <div className="card-inner">
              <div className="first-front">
                <div className="imgs">
                  <img src="./images/summitaward.jpg" alt="" />
                </div>

                <div className="conss">
                  <p>
                    <h5>हेपफ्र इन्टरनेशनल नेपाल</h5>

                    <button>
                      <img
                        src="./images/heifra.gif"
                        height={40}
                        width={40}
                        alt=""
                      />
                    </button>
                  </p>
                </div>
              </div>
              <div className="chro-btn">
                <img src="./images/logo.png" alt="" />
                <button>Read More </button>
                <p className="ps-5 ms-3">हेपफ्र इन्टरनेशनल नेपाल</p>
              </div>
            </div>
          </div>
          <div className="chronicle-card  mb-5">
            <div className="card-inner">
              <div className="first-front">
                <div className="imgs">
                  <img src="./images/summitaward.jpg" alt="" />
                </div>

                <div className="conss">
                  <p>
                    <h5>
                      {" "}
                      Confederation of Fanks and Financial Institutions, Nepal
                    </h5>

                    <button>
                      <img src="./images/cbfin.png" width={40} alt="" />
                    </button>
                  </p>
                </div>
              </div>
              <div className="chro-btn">
                <img src="./images/logo.png" alt="" />

                <button>Read More </button>
                <p className="ps-4 ms-2">
                  {" "}
                  Confederation of Banks and Financial Institutions, Nepal
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Partners;
