import React from "react";
import "./style.css";

const Welcome = () => {
  return (
    <div>
      <div className="loading">
        <center>
          <div className="col-md-2">
            <img
              src="./images/nbilogo.png"
              style={{ height: "auto" }}
              className="w-100 ps-3 pe-3"
              alt=""
            />
          </div>
        </center>
        <div className="welcomes pt-5">
          <h2>
            Welcome To Nepal <span>Business</span> Institute
          </h2>
        </div>
      </div>
    </div>
  );
};

export default Welcome;
