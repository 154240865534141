import React from "react";
import "./style.css";
import { GoCalendar } from "react-icons/go";

const News = () => {
  return (
    <div>
      <div className="about-head p-3 p-md-5">
        <div className="container ">
          <h2 className="text-center">News Activities</h2>
        </div>
      </div>
      <div className="container about pt-2 pt-md-5 pb-2 pb-md-5">
        <div className="row">
          <div className="col-lg-4 col-md-6 mb-4 p-4">
            <div className="news-card">
              <img src="./images/news4.jpg" alt="" className="w-100" />
              <h5 className="pt-3">
                महासंघको सञ्चार परामर्शदातामा नेपाल बिजनेश डटकम (भिडियोसहित)
              </h5>
              <p className="miti">प्रकाशित मिति : ६ पुष २०७९, बुधबार १२:५९</p>
              <div className="date-read">
                <div className="date">
                  <GoCalendar className="me-1" />
                  23-Dec-2022
                </div>
                <div className="read-more">
                  <a
                    href="https://www.nepalbusiness.com/News/Details/3694?fbclid=IwAR1ELkwkxa62Ycz82_9WZ4vVdxhP8pVBSvLqGX8qhozh4Y1Fxf0s1-9IFlE"
                    target="__blank"
                    className="text-decoration-none"
                  >
                    <p>Read More</p>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 mb-4 p-4">
            <div className="news-card">
              <img src="./images/news3.jpg" alt="" className="w-100" />
              <h5 className="pt-3">
                सातै प्रदेशमा ‘प्रादेशिक उद्यमशिलता परिसंवाद’ आयोजना हुँदै
              </h5>
              <p className="miti">प्रकाशित मिति : ९ फागुन, २०७८</p>
              <div className="date-read">
                <div className="date">
                  <GoCalendar className="me-1" />
                  21-Feb022
                </div>
                <div className="read-more">
                  <a
                    href="https://www.nepalbusiness.com/News/Details/2355?fbclid=IwAR3l77bLWgG_4pAkBzosYFZGnK2Nz_4jGZXHivGrN7DG5b0qAE4NDz5-y3U"
                    target="__blank"
                    className="text-decoration-none"
                  >
                    <p>Read More</p>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 mb-4 p-4">
            <div className="news-card">
              <img src="./images/news1.jpg" alt="" className="w-100" />
              <h5 className="pt-3">
                ‘नेपाल व्यवसाय शिखर सम्मेलन २०२३’ आयोजना हुने
              </h5>
              <p className="miti">प्रकाशित मिति : ६ पुष २०७९, बुधबार १२:५९</p>
              <div className="date-read">
                <div className="date">
                  <GoCalendar className="me-1" />
                  23-Dec-2022
                </div>
                <div className="read-more">
                  <a
                    href="https://clickmandu.com/2022/12/225401.html"
                    target="__blank"
                    className="text-decoration-none"
                  >
                    <p>Read More</p>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default News;
