import React from "react";
import "./style.css";
import { BiBuildings, BiEnvelope } from "react-icons/bi";

const SeniorConsultant = () => {
  return (
    <div>
      <div className="leader-title pt-4">
        <h3>Advisory / Expert Team </h3>
      </div>
      <div className="pb-5 pt-5 cards">
        <div className="col-lg-6 p-4 mb-4 mb-md-0">
          <div className="business-card p-4">
            <div className="col-md-4">
              <img
                src="./images/advisory/prakash.jpg"
                className="w-100"
                alt=""
              />
            </div>
            <div className="col-md-8 ps-4">
              <div className="business-card-content pt-2">
                <h3>Dr. Prakesh Kumar Shrestha</h3>
                <p>Financial Export</p>
                <li>
                  <BiBuildings />
                  ED, NRB
                </li>
                {/* <li>
                  <BiEnvelope /> president@chaudharygroup.com
                </li> */}
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-6 p-4 mb-4 mb-md-0">
          <div className="business-card p-4">
            <div className="col-md-4">
              <img src="./images/advisory/man.jpg" className="w-100" alt="" />
            </div>
            <div className="col-md-8 ps-4">
              <div className="business-card-content pt-2">
                <h3>Dr. Man Bahadur BK</h3>
                <p>Former Secretary</p>
                <li>
                  <BiBuildings /> Government of Nepal
                </li>
                {/* <li>
                  <BiEnvelope /> president@chaudharygroup.com
                </li> */}
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-6 p-4 mb-4 mb-md-0">
          <div className="business-card p-4">
            <div className="col-md-4">
              <img src="./images/advisory/arun.jpg" className="w-100" alt="" />
            </div>
            <div className="col-md-8 ps-4">
              <div className="business-card-content pt-2">
                <h3>Mr. Arun Nepal</h3>
                <p>Former Minister for Industry</p>
                <li>
                  <BiBuildings /> Policy Reform
                </li>
                {/* <li>
                  <BiEnvelope /> president@chaudharygroup.com
                </li> */}
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-6 p-4 mb-4 mb-md-0">
          <div className="business-card p-4">
            <div className="col-md-4">
              <img
                src="./images/advisory/naresh.jpg"
                className="w-100"
                alt=""
              />
            </div>
            <div className="col-md-8 ps-4">
              <div className="business-card-content pt-2">
                <h3>Mr. Naresh Prasad Shrestha</h3>
                <p>Academician</p>
                <li>
                  <BiBuildings /> Skill Training
                </li>
                {/* <li>
                  <BiEnvelope /> president@chaudharygroup.com
                </li> */}
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-6 p-4 mb-4 mb-md-0">
          <div className="business-card p-4">
            <div className="col-md-4">
              <img src="./images/advisory/bista.jpg" className="w-100" alt="" />
            </div>
            <div className="col-md-8 ps-4">
              <div className="business-card-content pt-2">
                <h3>Mr. Nar Bahadur Bista</h3>
                <p>Entrepreneurship Development </p>
                <li>
                  <BiBuildings />
                </li>
                {/* <li>
                  <BiEnvelope /> president@chaudharygroup.com
                </li> */}
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-6 p-4 mb-4 mb-md-0">
          <div className="business-card p-4">
            <div className="col-md-4">
              <img
                src="./images/advisory/dharma.jpg"
                className="w-100"
                alt=""
              />
            </div>
            <div className="col-md-8 ps-4">
              <div className="business-card-content pt-2">
                <h3>Mr. Dharmaraj Reghmi</h3>
                <p>Corporate law and Business climates </p>
                <li>
                  <BiBuildings />
                </li>
                {/* <li>
                  <BiEnvelope /> president@chaudharygroup.com
                </li> */}
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-6 p-4 mb-4 mb-md-0">
          <div className="business-card p-4">
            <div className="col-md-4">
              <img src="./images/advisory/binod.jpg" className="w-100" alt="" />
            </div>
            <div className="col-md-8 ps-4">
              <div className="business-card-content pt-2">
                <h3>Mr. Binod Guragai</h3>
                <p>Finanical / Business Export </p>
                <li>
                  <BiBuildings />
                </li>
                {/* <li>
                  <BiEnvelope /> president@chaudharygroup.com
                </li> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SeniorConsultant;
