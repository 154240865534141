import React from "react";
import "./style.css";

const Internship = () => {
  return (
    <div>
      <div className="about-head p-3 p-md-5">
        <div className="container ">
          <h2 className="text-center">Internship</h2>
        </div>
      </div>
      <div className="container about pt-2 pt-md-5 pb-2 pb-md-5 pt-5 pb-5">
        <div className="talk pt-5 pb-5 p-4">
          <div className="col-md-10 offset-md-1">
            <div className="talks">{/* <h3>Talk to Us: </h3> */}</div>
            <div className="row  pt-5 pb-5">
              <div className="col-lg-3 col-md-6 checks">
                <div className="mb-2 mt-2">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    id="exampleCheck1"
                  />
                  <label
                    className="form-check-label ps-1"
                    htmlFor="exampleCheck1"
                  >
                    Skill Lab Program
                  </label>
                </div>
                <div className="mb-2 mt-2">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    id="exampleCheck1"
                  />
                  <label
                    className="form-check-label ps-1"
                    htmlFor="exampleCheck1"
                  >
                    Summit &amp; Awards Programs
                  </label>
                </div>
                <div className="mb-2 mt-2">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    id="exampleCheck1"
                  />
                  <label
                    className="form-check-label ps-1"
                    htmlFor="exampleCheck1"
                  >
                    Start-up Fund Program
                  </label>
                </div>
                <div className="mb-2 mt-2">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    id="exampleCheck1"
                  />
                  <label
                    className="form-check-label ps-1"
                    htmlFor="exampleCheck1"
                  >
                    Business Incubation Program
                  </label>
                </div>
                <div className="mb-2 mt-2">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    id="exampleCheck1"
                  />
                  <label
                    className="form-check-label ps-1"
                    htmlFor="exampleCheck1"
                  >
                    Industrial visits Program
                  </label>
                </div>
                <div className="mb-2 mt-2">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    id="exampleCheck1"
                  />
                  <label
                    className="form-check-label ps-1"
                    htmlFor="exampleCheck1"
                  >
                    Business bootcamp programs
                  </label>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 checks">
                <div className="mb-2 mt-2">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    id="exampleCheck1"
                  />
                  <label
                    className="form-check-label ps-1"
                    htmlFor="exampleCheck1"
                  >
                    Fintech Program
                  </label>
                </div>
                <div className="mb-2 mt-2">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    id="exampleCheck1"
                  />
                  <label
                    className="form-check-label ps-1"
                    htmlFor="exampleCheck1"
                  >
                    Business consultant
                  </label>
                </div>
                <div className="mb-2 mt-2">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    id="exampleCheck1"
                  />
                  <label
                    className="form-check-label ps-1"
                    htmlFor="exampleCheck1"
                  >
                    Nepal Business Summit
                  </label>
                </div>
                <div className="mb-2 mt-2">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    id="exampleCheck1"
                  />
                  <label
                    className="form-check-label ps-1"
                    htmlFor="exampleCheck1"
                  >
                    Nepal Business School
                  </label>
                </div>
                <div className="mb-2 mt-2">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    id="exampleCheck1"
                  />
                  <label
                    className="form-check-label ps-1"
                    htmlFor="exampleCheck1"
                  >
                    Made in Nepal
                  </label>
                </div>
                <div className="mb-2 mt-2">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    id="exampleCheck1"
                  />
                  <label
                    className="form-check-label ps-1"
                    htmlFor="exampleCheck1"
                  >
                    Nepal Business Index
                  </label>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 form  pt-3 pt-lg-0">
                <div className="form_lebal">
                  <input
                    type="text"
                    id="fname"
                    required
                    placeholder="Full name *"
                  />
                </div>
                <div className="form_lebal mt-3">
                  <input
                    type="number"
                    id="number"
                    required
                    placeholder="Mobile number * "
                  />
                </div>
                <div className="form_lebal mt-3">
                  <input
                    type="email"
                    id="email"
                    required
                    placeholder="Email Address *"
                  />
                </div>
              </div>
              <div className="col-lg-3 col-md-6 pt-3 pt-lg-0">
                <div className="form_lebal">
                  <textarea
                    name="message"
                    id="message"
                    cols="30"
                    rows="5"
                    className="w-100 p-1"
                    placeholder="Your message *"
                  ></textarea>
                </div>
                <div className="more-button pt-2">
                  <center>
                    <button>Submit</button>
                  </center>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Internship;
