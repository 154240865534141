import React from "react";
import "./style.css";

const Gallery = () => {
  return (
    <div>
      <div className="about-head p-3 p-md-5">
        <div className="container ">
          <h2 className="text-center">Gallery</h2>
        </div>
      </div>
      <div className="container about pt-2 pt-md-5 pb-2 pb-md-5 pt-5 pb-5">
        <div className="gallery">
          <div className="row p-lg-5">
            <div className="col-md-4">
              <div className="gallery-img">
                <img src="./images/gall1.jpg" className="w-100" alt="" />
              </div>
            </div>
            <div className="col-md-8 gallerys">
              <div className="gallery-head pt-5 ps-2">
                <div className="title">
                  <h5>NEAPL BUSINESS INSTITUTE</h5>
                </div>
                <div className="more">
                  <button>VIEW GALLERY</button>
                </div>
              </div>
              <div className="d-flex pt-4">
                <div className="col-4 p-2 images">
                  <img src="./images/gall1.jpg" className="w-100 img1" alt="" />
                </div>
                <div className="col-4 p-2 images">
                  <img src="./images/gall1.jpg" className="w-100 img2" alt="" />
                </div>
                <div className="col-4 p-2 images">
                  <img src="./images/gall1.jpg" className="w-100 img3" alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Gallery;
