import React from "react";
import "./style.css";

const Publication = () => {
  return (
    <div>
      <div className="about-head p-3 p-md-5">
        <div className="container ">
          <h2 className="text-center">Publications</h2>
        </div>
      </div>
      <div className="container about pt-2 pt-md-5 pb-2 pb-md-5">
        <div className="col-lg-8 offset-lg-2 p-3 p-md-0">
          <div className="pb-md-5">
            <div
              className="publication d-flex pb-4"
              style={{ borderBottom: "1px solid #ccc" }}
            >
              <div className="col-md-8">
                <p className="first-title pb-md-3">
                  Summary of Citizens Education Report (CER)
                </p>
                <p className="second-title pb-md-4">
                  Summary of Citizens Education Report (CER)
                </p>
                <p>...</p>
              </div>
              <div className="col-md-4 pimg">
                <img src="./images/publicatiion.png" className="w-100" alt="" />
              </div>
            </div>
          </div>
          <div className="pb-md-5">
            <div
              className="publication d-flex pb-4"
              style={{ borderBottom: "1px solid #ccc" }}
            >
              <div className="col-md-8">
                <p className="first-title pb-md-3">
                  Summary of Citizens Education Report (CER)
                </p>
                <p className="second-title pb-md-4">
                  Summary of Citizens Education Report (CER)
                </p>
                <p>...</p>
              </div>
              <div className="col-md-4 pimg">
                <img src="./images/publicatiion.png" className="w-100" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Publication;
