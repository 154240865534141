import React from "react";
import "./style.css";

const SpeechVideos = () => {
  return (
    <div>
      <div className="about-head p-3 p-md-5">
        <div className="container ">
          <h2 className="text-center">Speech / Videos</h2>
        </div>
      </div>
      <div className="container about pt-2 pt-md-5 pb-2 pb-md-5">
        <div className="row">
          <div className="col-lg-4 col-md-6 P-4">
            <div className="video p-3">
              <iframe
                className="w-100"
                height={280}
                src="https://www.youtube.com/embed/F1fmKo5AQoI"
                title="YouTube video player"
                frameBorder={0}
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen=""
              />
            </div>
          </div>
          <div className="col-lg-4 col-md-6 P-4">
            <div className="video p-3">
              <iframe
                className="w-100"
                height={280}
                src="https://www.youtube.com/embed/x5beW-QteIo"
                title="YouTube video player"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen
              />
            </div>
          </div>
          <div className="col-lg-4 col-md-6 P-4">
            <div className="video p-3">
              <iframe
                className="w-100"
                height={280}
                src="https://www.youtube.com/embed/5pa6JFBgIKY"
                title="YouTube video player"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SpeechVideos;
