import React from "react";
import BusinessThinktank from "./BusinessThinktank";
import SeniorConsultant from "./SeniorConsultant";

const Leader = () => {
  return (
    <div>
      <BusinessThinktank />
      <SeniorConsultant />
    </div>
  );
};

export default Leader;
