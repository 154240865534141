import React from "react";
import "./style.css";

const Volunteer = () => {
  return (
    <div>
      <div className="about-head p-3 p-md-5">
        <div className="container ">
          <h2 className="text-center">Volunteer</h2>
        </div>
      </div>
      <div className="container about  pb-2 pb-md-5 pb-5">
        <div className="volunteer">
          <div className="nvol">
            <p>Number of Present Volunteers: 10</p>
            <hr />
          </div>
          <div className="volunteers">
            <div className="volunteer_member">
              <div className="cardss">
                <div className="layer"></div>
                <div className="content">
                  <img src="./images/logo.png" alt="" />
                </div>
              </div>
              <div className="name_detail">
                <p className="name">
                  <span>Name:</span> Lorem Ipsum
                </p>
                <p className="address">
                  <span>Faculty:</span>Lorem Ipsum
                </p>
                <p className="address">
                  <span>Email:</span>lorem@gmail.com
                </p>
                <p className="bloodg">
                  <span>Blood Group:</span>AB<sup>+</sup>
                </p>
                {/* <p className="vdate"><i class="far fa-calendar-alt"></i> 2077/1/3 - 2078/1/3</p> */}
              </div>
            </div>
            <div className="volunteer_member">
              <div className="cardss">
                <div className="layer"></div>
                <div className="content">
                  <img src="./images/logo.png" alt="" />
                </div>
              </div>
              <div className="name_detail">
                <p className="name">
                  <span>Name:</span> Lorem Ipsum
                </p>
                <p className="address">
                  <span>Faculty:</span>Lorem Ipsum
                </p>
                <p className="address">
                  <span>Email:</span>lorem@gmail.com
                </p>
                <p className="bloodg">
                  <span>Blood Group:</span>AB<sup>+</sup>
                </p>
                {/* <p className="vdate"><i class="far fa-calendar-alt"></i> 2077/1/3 - 2078/1/3</p> */}
              </div>
            </div>
            <div className="volunteer_member">
              <div className="cardss">
                <div className="layer"></div>
                <div className="content">
                  <img src="./images/logo.png" alt="" />
                </div>
              </div>
              <div className="name_detail">
                <p className="name">
                  <span>Name:</span> Lorem Ipsum
                </p>
                <p className="address">
                  <span>Faculty:</span>Lorem Ipsum
                </p>
                <p className="address">
                  <span>Email:</span>lorem@gmail.com
                </p>
                <p className="bloodg">
                  <span>Blood Group:</span>AB<sup>+</sup>
                </p>
                {/* <p className="vdate"><i class="far fa-calendar-alt"></i> 2077/1/3 - 2078/1/3</p> */}
              </div>
            </div>
            <div className="volunteer_member">
              <div className="cardss">
                <div className="layer"></div>
                <div className="content">
                  <img src="./images/logo.png" alt="" />
                </div>
              </div>
              <div className="name_detail">
                <p className="name">
                  <span>Name:</span> Lorem Ipsum
                </p>
                <p className="address">
                  <span>Faculty:</span>Lorem Ipsum
                </p>
                <p className="address">
                  <span>Email:</span>lorem@gmail.com
                </p>
                <p className="bloodg">
                  <span>Blood Group:</span>AB<sup>+</sup>
                </p>
                {/* <p className="vdate"><i class="far fa-calendar-alt"></i> 2077/1/3 - 2078/1/3</p> */}
              </div>
            </div>
            <div className="volunteer_member">
              <div className="cardss">
                <div className="layer"></div>
                <div className="content">
                  <img src="./images/logo.png" alt="" />
                </div>
              </div>
              <div className="name_detail">
                <p className="name">
                  <span>Name:</span> Lorem Ipsum
                </p>
                <p className="address">
                  <span>Faculty:</span>Lorem Ipsum
                </p>
                <p className="address">
                  <span>Email:</span>lorem@gmail.com
                </p>
                <p className="bloodg">
                  <span>Blood Group:</span>AB<sup>+</sup>
                </p>
                {/* <p className="vdate"><i class="far fa-calendar-alt"></i> 2077/1/3 - 2078/1/3</p> */}
              </div>
            </div>
            <div className="volunteer_member">
              <div className="cardss">
                <div className="layer"></div>
                <div className="content">
                  <img src="./images/logo.png" alt="" />
                </div>
              </div>
              <div className="name_detail">
                <p className="name">
                  <span>Name:</span> Lorem Ipsum
                </p>
                <p className="address">
                  <span>Faculty:</span>Lorem Ipsum
                </p>
                <p className="address">
                  <span>Email:</span>lorem@gmail.com
                </p>
                <p className="bloodg">
                  <span>Blood Group:</span>AB<sup>+</sup>
                </p>
                {/* <p className="vdate"><i class="far fa-calendar-alt"></i> 2077/1/3 - 2078/1/3</p> */}
              </div>
            </div>
            <div className="volunteer_member">
              <div className="cardss">
                <div className="layer"></div>
                <div className="content">
                  <img src="./images/logo.png" alt="" />
                </div>
              </div>
              <div className="name_detail">
                <p className="name">
                  <span>Name:</span> Lorem Ipsum
                </p>
                <p className="address">
                  <span>Faculty:</span>Lorem Ipsum
                </p>
                <p className="address">
                  <span>Email:</span>lorem@gmail.com
                </p>
                <p className="bloodg">
                  <span>Blood Group:</span>AB<sup>+</sup>
                </p>
                {/* <p className="vdate"><i class="far fa-calendar-alt"></i> 2077/1/3 - 2078/1/3</p> */}
              </div>
            </div>
            <div className="volunteer_member">
              <div className="cardss">
                <div className="layer"></div>
                <div className="content">
                  <img src="./images/logo.png" alt="" />
                </div>
              </div>
              <div className="name_detail">
                <p className="name">
                  <span>Name:</span> Lorem Ipsum
                </p>
                <p className="address">
                  <span>Faculty:</span>Lorem Ipsum
                </p>
                <p className="address">
                  <span>Email:</span>lorem@gmail.com
                </p>
                <p className="bloodg">
                  <span>Blood Group:</span>AB<sup>+</sup>
                </p>
                {/* <p className="vdate"><i class="far fa-calendar-alt"></i> 2077/1/3 - 2078/1/3</p> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Volunteer;
